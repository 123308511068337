import { Link } from "react-router-dom";
import { Container, Row, Col, Carousel, Button, Image, Card } from "react-bootstrap";


import "./hrpage.css";
import policy from "../../imgs/policy.png"
import benefits from "../../imgs/benefits.jpg";
import newhire from "../../imgs/newhire.jpg";
import medical from "../../imgs/medical.jpg";
import blood from "../../imgs/blooddraw.jpg"
import metlife from "../../imgs/metlife.jpg"
import healthins from "../../imgs/health-insurance.png"
import dental from "../../imgs/dental.jpg"
import contract from "../../imgs/contract.jpg"
import rewards from "../../imgs/Anthem-rewards-card.png"
import logo from "../../imgs/anthemlogo.png"
import dollar from "../../imgs/dollar.jpg"
import metlogo from "../../imgs/metlife_eng_logo_rgb.png"

const HRPage = () => {
  
return <>
<Container className="content-section">
<h1>Penny Plate Benefits & Enrollment Information</h1>

</Container>
<Container className="heading">
<h2><ins>Introduction</ins></h2>
<p class='heading'>The new hire enrollment period is your opportunity to learn about the benefit offerings and choose the best options for you and your family for the plan year. Please review our benefit information found below to learn more.</p>
</Container>

<Container className="heading">
<h2><ins>How To Enroll</ins></h2> 
<p>
      <ul>
        <li>If you'd like to enroll, you will need to complete an enrollment form and return it to the HR department.</li>
        <li>No changes will be allowed outside of the new hire enrollment period unless there is a qualifying life event.</li>
        <li>If you do not wish to enroll in benefits, no action is needed.</li>
        </ul>
        </p>  
</Container>
<Container className="heading">
  <h2><ins>Enrollment Forms</ins></h2>
  <div><ul>
    <li><a href="https://packagingmattersusa.sharepoint.com/:b:/s/PennyPlateHR/ERRFXpe_SmFLrs5ZFrbvFKUBjTySzH2-2eHmb01bE7S5Zg">2024 Monthly Payroll Enrollment Form
</a> </li>
<li><a href="https://packagingmattersusa.sharepoint.com/:b:/s/PennyPlateHR/EUvMfUHMZ-FKo1U3-9SlBu0BmTXMdNAesmQvmc0WFtyCgQ">2024 Weekly Payroll Enrollment Form
</a> </li>
    

    
    </ul></div>
</Container>

<Container className="heading">
<h2><ins>Benefits Overview</ins></h2> 
<p>Find all of your benefit plan options for the current plan year within the below Benefit Guides.</p>
</Container>
<Container className="heading"><h2><ins>Benefit Guides</ins></h2>
<div className="grid">
<a href="https://packagingmattersusa.sharepoint.com/:b:/s/PennyPlateHR/EXiCaV5Vdx1AqCd7xqSIXFgBRtXA7qeGdRxFYahWBZrNhg?e=sI7Xho"><h3>2024 Penny Plate Benefits Guide</h3><img src={healthins} className='photo' alt="handshake" />
</a>
<a href="https://packagingmattersusa.sharepoint.com/:b:/s/PennyPlateHR/EXKzw0kqcOZJoWliPY0aC3IBc-eTqdXjtA6QY4bhvRJr3w?e=JLuhAL"><h3>2024 Anthem Booklet </h3><img src={logo} className='photo' alt="handshake" />
</a>
</div>
</Container>

<Container className="heading">
  <h3><ins>HSA Resources</ins></h3>
<div class="grid">
<a href="https://packagingmattersusa.sharepoint.com/:b:/s/PennyPlateHR/EQzgIAhb6nVIkqy_HQCP_gsBX5TZNDGnPF9qzeZhLUY3Rw?e=d4Zgbw"><h3>2024 Anthem HSA Benefit Guide</h3><img src={blood} className='photo' alt="handshake" />
</a>
<a href="https://packagingmattersusa.sharepoint.com/:b:/s/PennyPlateHR/Eak0SfjjDFZBiSv1WJNkIUcBtN8WZKwzB0quXJ4m0eOfMw"><h3>Anthem Debit Card </h3><img src={rewards} className='photo' alt="rewardscard" />
</a>
<a href="https://packagingmattersusa.sharepoint.com/:b:/s/PennyPlateHR/EXKQoclWXpFJkovtUd2sd-oBD7iFRGkybW8ESgy8ZvXF1A?e=bjCLfP"><h3>Anthem Spending Account Member Portal Guide </h3><img src={medical} className='photo' alt="handshake" />
</a>
<a href="https://packagingmattersusa.sharepoint.com/:b:/s/PennyPlateHR/EaKauiL-7sNHp6DSmeeWblcBcSDPYa5sj__7jpOR90wnUA?e=KyX2pl"><h3>HSA Transfer of Assets Form </h3><img src={logo} className='photo' alt="rewardscard" />
</a>
<a href="https://packagingmattersusa.sharepoint.com/:b:/s/PennyPlateHR/EVP-L0N0Hw9DghvH4XLAOxcBDG3HNPPGmJQc0V7pVYzsrg?e=cv6Gqr"><h3>HSA Fee Chart </h3><img src={dollar} className='photo' alt="rewardscard" />
</a>
<a href="https://packagingmattersusa.sharepoint.com/:b:/s/PennyPlateHR/EWmaB7N4-BlJtU3o85jjHvABLlVH0RLm-Fsdw3T0J0jy9Q?e=cIVP3w"><h3>Optum Fee Chart </h3><img src={dollar} className='photo' alt="rewardscard" />
</a>
</div>

</Container>

<Container className="heading">
  <h3><ins>Anthem Resources</ins></h3><img src={logo} className='logo' alt="handshake" />
<div class="grid">
<a href="https://packagingmattersusa.sharepoint.com/:b:/s/PennyPlateHR/EXz0YGn3DcNJslrFRrF0-zUB8Ut1sUddiBzZSe1FupREJA?e=WwqsWi"><h3>BlueCard PPO Travel Benefits</h3>
</a>
<a href="https://packagingmattersusa.sharepoint.com/:b:/s/PennyPlateHR/EQFvUfO_LIhKuwUhudEZlVIBc6-_bma-IwvGjPGari1Diw?e=zwouYR"><h3>PreventiveRx Plus Drug List</h3>
</a>
<a href="https://packagingmattersusa.sharepoint.com/:b:/s/PennyPlateHR/EZr_4m6-uihGvBh0SNgAjT4BxgBrVGH23G2gzk7Jv9pbGQ?e=6YMmxh"><h3>Rx Home Delivery</h3>
</a>
<a href="https://packagingmattersusa.sharepoint.com/:b:/s/PennyPlateHR/EQAjoRIeYTFHsWjn3624BREBqVNaUXztilW3VyOyzH94Ag?e=0QdHC4"><h3>Alternative Medication List</h3>
</a>

</div>

</Container>


<Container className="heading">
  <div><h3><ins>MetLife Basic Life and AD&D</ins></h3>
  <img src={metlogo} className='logo' alt="handshake" /></div>
<div class="grid">

<a href="https://packagingmattersusa.sharepoint.com/:b:/s/PennyPlateHR/EWaqLQUf7ipAk9pTnNP1JJsBudbvyuC-XGatgBJ2q4YOwg"><h3>Metlife Basic Life Benefit Summary</h3>
</a>
</div>
</Container>

<Container className="heading">
  <h3><ins>MetLife STD & LTD Resources</ins></h3>
<div class="grid">
<a href="https://packagingmattersusa.sharepoint.com/:b:/s/PennyPlateHR/EUfrcZydB4NGv3QrBjdJFVsBpFWgSUBrhwL3jdChq1_xSQ?e=swcy6I"><h3>Metlife STD Benefit Information</h3>
</a>
<a href="https://packagingmattersusa.sharepoint.com/:b:/s/PennyPlateHR/EWaqLQUf7ipAk9pTnNP1JJsBudbvyuC-XGatgBJ2q4YOwg?e=Y2Kpfy"><h3>Metlife LTD Benefit Information</h3>
</a>
</div>
</Container>

<Container className="heading">
  <h3><ins>MetLife Accident Insurance</ins></h3>
  <div class="grid">
  <a href="https://packagingmattersusa.sharepoint.com/:b:/s/PennyPlateHR/EX2N7GogmBRAko1fqoGJUycB3HfM1a3IXDCXqXcNgC83Aw?e=pIctq8"><h3>Weekly Payroll Accident Insurance Rates</h3>
</a>
<a href="https://packagingmattersusa.sharepoint.com/:b:/s/PennyPlateHR/EYGSWDOAOOJGiDsJlz8SmzEB3NhOwfx0Cb2FXYskdLEV2Q?e=YZ2gIh"><h3>Monthly Payroll Accident Insurance Rates</h3>
</a>   
  </div>
</Container>

<Container className="heading">
  <h3><ins>MetLife EAP</ins></h3>
  <div class="grid">
  <a href="https://packagingmattersusa.sharepoint.com/:b:/s/PennyPlateHR/EdtL3mWkgnJCmKfgXbUa4x0BMpptQklJuMMkB8RIRA59YQ?e=Lszk6P"><h3>MetLife Employee Assistance Program</h3>
  </a>
  <a href="https://packagingmattersusa.sharepoint.com/:b:/s/PennyPlateHR/EQl_SHkoU4hFrbwY62Cb854BzZTQd6eJc142yzvmEFKO5A?e=9263xj"><h3>MetLife Employee Assistance Program Flyer</h3>
  </a>
  </div>
</Container>

  <Container className="heading">
  <h3><ins>MetLife Hospital Indemnity</ins></h3>
  <div >
  <a href="https://packagingmattersusa.sharepoint.com/:b:/s/PennyPlateHR/EfWmdFFbmrJNk81u_evIQjUBeZKEeQjhSWLy0MXbgvfC7Q?e=Lb04ns"><h3>MetLife Hospital Insurance Summary</h3>
  </a>
  </div>
</Container>

  <Container className="heading">
  <h3><ins>MetLife Supplemental Life Insurance</ins></h3>
  <div >
  <a href="https://packagingmattersusa.sharepoint.com/:w:/s/PennyPlateHR/EWoVah4b4fFErZba19TocLQBAGNi4NLm2ruO0gskZrRw0A?e=vkq5vv"><h3>Metlife Statement of Health Form</h3>
</a>
  <a href="https://packagingmattersusa.sharepoint.com/:b:/s/PennyPlateHR/EfQp4b_csq1MgM8lkVdHmbIBYAI6eV1zg_T2s24UGa8B8Q?e=8C4V4F"><h3>Monthly Payroll MetLife Supplemental Life Insurance Summary</h3>
  </a>
  <a href="https://packagingmattersusa.sharepoint.com/:b:/s/PennyPlateHR/EYp3KcZ9jj1Du0BmVe3uC64BHrxsMCiSo8y3XW88YP5iMQ"><h3>Weekly Payroll MetLife Supplemental Life Insurance Summary</h3>
  </a>
  </div>
</Container>


<Container className="heading">
<h2><ins>Compliance Information</ins></h2>
<a href="https://packagingmattersusa.sharepoint.com/:b:/s/PennyPlateHR/Ef65mdsglrtFn9rxnXj2gh0Bsr0bIXAk0vMk3_Z09Vj7HQ"><h3>2024 Health Plan Compliance Notices </h3>
</a>
</Container>

<Container >
<h1>Additional Resources</h1>
<div>
  <h2><ins>Access & How To</ins></h2>
  <a href="https://packagingmattersusa.sharepoint.com/:b:/s/PennyPlateHR/ESmEDIZtFFZOjPrdgHqQa-4BuoVkS76AqiBPObtGPuKtRQ?e=jh6ltI"><h4>MetLife How to File a Claim</h4>
</a>
<a href="https://packagingmattersusa.sharepoint.com/:b:/s/PennyPlateHR/EQ2lPCp6gFpJkLZrsJXNQNUBnseilwjDrQqGyjxPVf5vZw?e=8OFzYj"><h4>MetLife MyBenefits Mobile App Flyer</h4>
</a>
<a href="https://packagingmattersusa.sharepoint.com/:b:/s/PennyPlateHR/EfxwdIHNaMRNrIn0MaBdxZgBfrI54_QSmsCIPAa_oCmb9w?e=xwtVQ1"><h4>MetLife MyBenefits Registration Instructions</h4>
</a>
</div>
<div>
  <h2><ins>Preventive Care</ins></h2>
  <a href="https://packagingmattersusa.sharepoint.com/:b:/s/PennyPlateHR/EYRHagWP0qZHrg8aiHS0FIUBUuPwut7LRx1hAu_gpsN3kw?e=IzI71K"><h4>Preventive Medical Care Benefits</h4>
</a>
</div>
<div>
  <h2><ins>Sydney Health App</ins></h2>
  <a href="https://packagingmattersusa.sharepoint.com/:b:/s/PennyPlateHR/EXHMnF4u9b1CovNhCGcVC_0Bc0gLPGLz15GDZaoH4ap8wQ?e=xntsHb"><h4>Sydney Health Mobile App</h4>
</a>
</div>
<div><h2><ins>Virtual Care</ins></h2>
<a href="https://packagingmattersusa.sharepoint.com/:b:/s/PennyPlateHR/Ecbmuir_kSRJmUD8229vkMABgcVu866zmyQ4l8ZxZ5lE9A?e=50sEXS"><h4>Virtual Care</h4>
</a>
</div>
<div>
  <h2><ins>Wellness Incentives, Perks, & More</ins></h2>
<a href="https://packagingmattersusa.sharepoint.com/:b:/s/PennyPlateHR/ESPZW-ABtfBAo9uAxfN1JgQBxJ1TB9IRPJbduUf_JbAMfA?e=oVWUXj"><h4>Wellness Summary</h4>
</a>
<a href="https://packagingmattersusa.sharepoint.com/:b:/s/PennyPlateHR/EfERjlO5puhNqIeNfofEMhEBn-cBfPlMYO0ZuYnyGtQZCA?e=vGXcQ4"><h4>Diabetes Prevention</h4>
</a>
<a href="https://packagingmattersusa.sharepoint.com/:b:/s/PennyPlateHR/EboaZyWGMkhLoQZKISEaJIoB4Ickem22xw3nohkrAbYgMQ?e=ehQgdc"><h4>Enhanced Personal Health Care</h4>
</a>
<a href="https://packagingmattersusa.sharepoint.com/:b:/s/PennyPlateHR/EZERp_c31hNBoah9b6oBotMBSe7ceNkt7PTk6br634mfcg?e=79o0Lz"><h4>Member Incentives Earn up to $200</h4>
</a>
<a href="https://packagingmattersusa.sharepoint.com/:b:/s/PennyPlateHR/ESOhoa300I5NsFk9-LdAy-EBFZd4HUxSk0dwWJiRFsEkPA?e=dfMK9d"><h4>Special Offers and Discounts</h4>
</a>
</div>
<div>
  <h2><ins>Certificates of Insurance</ins></h2>
<a href="https://packagingmattersusa.sharepoint.com/:b:/s/PennyPlateHR/Ee70t9Y0iAtFkoutfKkVD5sBrojFg8yRHljTg0JodoDLhQ?e=wsZG0p"><h4>Hospital Indemnity Certificate of Insurance</h4>
</a>
<a href="https://packagingmattersusa.sharepoint.com/:b:/s/PennyPlateHR/EfIpvyPCQ7hEvOe3jUPNnJQBi7-N1Y2uZnKFxyFET_QcZA?e=dse2ha"><h4>Voluntary Accident Certificate of Insurance</h4>
</a>
<a href="https://packagingmattersusa.sharepoint.com/:b:/s/PennyPlateHR/EVWAYkG3iJlOmy1EaOuZyMcBv15AWwa-ILb-ixsO_piWvg?e=UPs2hm"><h4>Long Term Disability Certificate of Insurance</h4>
</a>
<a href="https://packagingmattersusa.sharepoint.com/:b:/s/PennyPlateHR/EaqUWlJC6G1Ihwvo_TYti7MBraLQsQOWtq4_Vy5S2ZahVg?e=u70ZB4"><h4>Short Term Disability Certificate of Insurance</h4>
</a>
<a href="https://packagingmattersusa.sharepoint.com/:b:/s/PennyPlateHR/ESRZF1G48ihLhk_Q3fspqcgB8hq-C4wTwxLCW0jVti-svw?e=iJE1LS"><h4>Voluntary Life & AD&D Certificate of Coverage</h4>
</a>
<a href="https://packagingmattersusa.sharepoint.com/:b:/s/PennyPlateHR/EaeHwApz_NFAizqNBHArZekBry9xVF_r8sWDKVBCXgIOyQ?e=d8VdL9"><h4>Basic Life & AD&D Certificate of Coverage</h4>
</a>

</div>
</Container>

  



</>
}

export default HRPage;
