import "./timeline.scss";

// Source: https://codepen.io/ygc/pen/AXzVya/
const Timeline = () => (
  <>
    <section id="cd-timeline" className="cd-container">
      <div className="cd-timeline-block">
        <div className="cd-timeline-img"></div> {/* cd-timeline-img */}
        <div className="cd-timeline-content red">
          <p>
            Founding of <b>Penny Plate Inc.</b>
          </p>
          <span className="cd-date">1948</span>
        </div>{" "}
        {/* cd-timeline-content */}
      </div>{" "}
      {/* cd-timeline-block */}
      <div className="cd-timeline-block">
        <div className="cd-timeline-img"></div> {/* cd-timeline-img */}
        <div className="cd-timeline-content">
          <p>
            <b>Therma Plate</b> was founded and emerged as the primary producer
            of CPET Plastic Trays. The company is now part of Bemis Corporation.
          </p>
          <span className="cd-date">1986</span>
        </div>{" "}
        {/* cd-timeline-content */}
      </div>{" "}
      {/* cd-timeline-block */}
      <div className="cd-timeline-block">
        <div className="cd-timeline-img"></div> {/* cd-timeline-img */}
        <div className="cd-timeline-content">
          <p>
            <b>Associated Packaging Technologies</b> was founded and
            successfully developed the technology to resolve the cracking issues
            of CPET trays. The company now part of Sonoco Products Company.
          </p>
          <span className="cd-date">1993</span>
        </div>{" "}
        {/* cd-timeline-content */}
      </div>{" "}
      {/* cd-timeline-block */}
      <div className="cd-timeline-block">
        <div className="cd-timeline-img"></div> {/* cd-timeline-img */}
        <div className="cd-timeline-content">
          <p>
            <b>Peninsula Packaging</b> was founded for the Agriculture Industry
            and formed recyclable berry containers using solar energy. The
            company is now part of Sonoco Products Company.
          </p>
          <span className="cd-date">2002</span>
        </div>{" "}
        {/* cd-timeline-content */}
      </div>{" "}
      {/* cd-timeline-block */}
      <div className="cd-timeline-block">
        <div className="cd-timeline-img"></div> {/* cd-timeline-img */}
        <div className="cd-timeline-content">
          <p>
            <a href="https://www.polymerpak.com/">
              <b>PolymerPak</b>
            </a>{" "}
            was founded.
          </p>
          <span className="cd-date">2015</span>
        </div>{" "}
        {/* cd-timeline-content */}
      </div>{" "}
      {/* cd-timeline-block */}
      <div className="cd-timeline-block">
        <div className="cd-timeline-img"></div> {/* cd-timeline-img */}
        <div className="cd-timeline-content red">
          <p>
            Penny Plate, LLC. becomes a subsidiary of{" "}
            <a href="https://packagingmatters.com/">
              <b>Packaging Matters, LLC</b>
            </a>
            .
          </p>
          <span className="cd-date">2020</span>
        </div>{" "}
        {/* cd-timeline-content */}
      </div>{" "}
      {/* cd-timeline-block */}
    </section>{" "}
    {/* cd-timeline */}
  </>
);

export default Timeline;
